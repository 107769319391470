import IMask from 'imask';
import bind from 'bind-decorator';

import CustomSelect from './custom_select';
import View from './view';

export default class PhoneWithCountryCode extends View {
  initialize() {
    this.$customSelect = $('.country_code', this.node);
    this.$countryCode = $('select', this.$customSelect);
    this.$phoneNumber = $('input[type=tel]', this.node);

    if (!this.$customSelect.length) {
      return this.error('missing .country_code');
    }
    if (!this.$countryCode.length) {
      return this.error('missing select');
    }
    if (!this.$phoneNumber.length) {
      return this.error('missing input[type=tel]');
    }

    this.customSelect = new CustomSelect(this.$customSelect[0]);

    this.iMask = null;

    this.$countryCode
      .on('change', this.syncImask)
      .on('change', this.focusPhoneNumber);

    this.syncImask();
  }

  error(message) {
    if (window.ENV === 'development') {
      throw new Error(message);
    } else {
      console.error(message); // eslint-disable-line
    }
  }

  @bind
  focusPhoneNumber() {
    this.$phoneNumber.focus();
  }

  @bind
  syncImask() {
    this.iMask?.destroy();
    this.iMask = new IMask(this.$phoneNumber[0], { mask: this.$countryCode.val() });
  };

  destroy() {
    this.iMask?.destroy();
    this.customSelect.destroy();
  }
}
